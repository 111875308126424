function useAccessPage() {

  const page = ref<IPage>()

  return {
    page
  }

}

export default createGlobalState(useAccessPage)